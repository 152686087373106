import { createTheme } from '@mui/material/styles';
import { FONTSIZE, COLOR } from 'common/constants/appInfos';

export const theme = createTheme({
    spacing: 2,
    typography: {
        fontFamily: ['LibreFranklin', 'NotoSansCJKsc'].join(',')
    },
    components: {
        MuiTextField: {
            defaultProps: {
                variant: 'standard'
            }
        },
        MuiFormControl: {
            defaultProps: {
                variant: 'standard'
            },
            styleOverrides: {
                root: {
                    "& .MuiInput-root": {
                        "&:after": {
                            borderColor: COLOR.BLUE,
                        }
                    }
                }
            }
        },
        MuiSelect: {
            defaultProps: {
                variant: 'standard'
            }
        },
        MuiTabs: {
            styleOverrides: {
                indicator: {
                    backgroundColor: COLOR.BLUE,
                }
            }  
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    "&.Mui-selected": {
                        color: COLOR.BLUE,
                    }
                }   
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    color: COLOR.BLACK,
                }
            }
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    zIndex: 1201
                }
            }
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    color: COLOR.BLACK
                }
            }
        },
        MuiAlert: {
            styleOverrides: {
                message: {
                    fontSize: FONTSIZE.BODY
                }
            }
        },
        MuiFormControlLabel: {
            styleOverrides: {
                labelPlacementStart: {
                    marginRight: 0
                }
            }
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    fontSize: FONTSIZE.BODY
                }
            }
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    color: COLOR.GREEN
                }
            }
        }
    }
});
