import theme from '../../theme.module.scss';

export const LANGUAGE_AVAILABLE = {
    en: 'en-US',
    sc: 'zh-CN'
};

export const FONTSIZE = {
    BODY: '14px',
    SIXTHEEN: '16px',
    TWENTY: '20px',
    TWENTYFOUR: '24px'
};

export const FONTWEIGHT = {
    LIGHT: '300',
    REGULAR: '400',
    MEDIUM: '500',
    SEMI_BOLD: '600',
    BOLD: '700',
    EXTRA_BOLD: '800',
    BLACK: '900'
};

export const COLOR = {
    GREEN: theme.COLOR_NAVIGATE,
    LIGHTGREEN: '#e5eff0',
    MINTGREEN: '#52d869',
    SACRAMENTOGREEN: '#002f33',

    BLUE: theme.COLOR_SECONDARY,
    AZURE: theme.COLOR_ACTIVE,
    SKYBLUE: theme.COLOR_PRIMARY,
    BACKGROUND_LIGHT: theme.COLOR_BACKGROUND_LIGHT,

    DANGERRED: '#e00000',
    CHERRYRED: '#c40000',
    DARKRED: '#ad1111',

    YELLOW: '#ffb200',
    TUSCANYYELLOW: '#f2a100',

    GREY: '#454b52',
    LIGHTGREY: '#f6f6f6',
    SMOKEGREY: '#ededed',
    STEELGREY: '#656565',
    CHARCOAL: '#333333',

    BLACK: '#000000',
    WHITE: '#ffffff'
};
